import React, { useContext } from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { FaShoppingCart } from "react-icons/fa"
import Img from "gatsby-image"
import { StoreContext } from "../context/StoreContext"
import logo from "../images/logo.png"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allMarkdownRemark(filter: { frontmatter: { active: { eq: true } } }) {
      edges {
        node {
          frontmatter {
            nav
            handle
          }
        }
      }
    }
  }
`

const Nav = props => {
  const { checkout } = useContext(StoreContext)

  const totalQuantity = checkout.lineItems.reduce(
    (total, item) => total + item.quantity,
    0
  )

  const toggleNavbar = () => {
    const burger = document.getElementsByClassName("navbar-burger")
    const menu = document.getElementsByClassName("navbar-menu")

    if (burger[0].classList.contains("is-active")) {
      burger[0].classList.remove("is-active")
    } else {
      burger[0].classList.add("is-active")
    }

    if (menu[0].classList.contains("is-active")) {
      menu[0].classList.remove("is-active")
    } else {
      menu[0].classList.add("is-active")
    }
  }

  console.log(props)
  return (
    <nav
      className="navbar"
      role="navigation"
      aria-label="main navigation"
      style={{ height: "5rem", boxShadow: "var(--elevation-2)" }}
    >
      <div className="navbar-brand" style={{ margin: 0, padding: 0 }}>
        <Link
          style={{
            marginLeft: 20,
            marginTop: 10,
          }}
          activeStyle={{ color: "#6cf" }}
          to={`/`}
        >
          <img src={logo} style={{ width: 200 }} />
        </Link>

        <a
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={toggleNavbar}
          style={{
            marginTop: 10,
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>

        <Link to="/cart">
          <button
            className="button is-hidden-desktop"
            style={{
              background: "transparent",
              border: "none",
              position: "relative",
              marginTop: 20,
              marginRight: 10,
            }}
          >
            {totalQuantity > 0 && (
              <div
                style={{
                  color: "white",
                  position: "absolute",
                  background: "var(--red)",
                  borderRadius: 15,
                  textAlign: "center",
                  height: 25,
                  top: -5,
                  right: -5,
                  width: 25,
                  lineHeight: "25px",
                }}
              >
                {totalQuantity}
              </div>
            )}
            <FaShoppingCart
              style={{ color: "#1979c3", height: 25, width: 25 }}
            />
          </button>
        </Link>
      </div>
      <div className="navbar-menu">
        <div className="navbar-start">
          <Link
            className="navbar-item"
            activeStyle={{ color: "#6cf" }}
            style={{ marginLeft: 20 }}
            to={`/`}
          >
            Home
          </Link>

          <StaticQuery
            query={query}
            render={({ allMarkdownRemark: { edges } }) =>
              edges.map(edge => (
                <Link
                  key={edge.node.frontmatter.handle}
                  className="navbar-item"
                  activeStyle={{ color: "#6cf" }}
                  style={{ marginLeft: 20 }}
                  to={`/product/${edge.node.frontmatter.handle}`}
                >
                  {edge.node.frontmatter.nav}
                </Link>
              ))
            }
          />

          <a
            className="navbar-item"
            style={{ marginLeft: 20 }}
            href={`/track`}
          >
            Track
          </a>

          <Link
            className="navbar-item"
            activeStyle={{ color: "#6cf" }}
            style={{ marginLeft: 20 }}
            to={`/about`}
          >
            About
          </Link>

          <Link
            className="navbar-item"
            activeStyle={{ color: "#6cf" }}
            style={{ marginLeft: 20 }}
            to={`/contact`}
          >
            Contact
          </Link>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <Link to="/cart">
              <button
                className="button is-hidden-mobile"
                style={{
                  background: "transparent",
                  border: "none",
                  position: "relative",
                  marginTop: 0,
                  marginRight: 10,
                }}
              >
                {totalQuantity > 0 && (
                  <div
                    style={{
                      color: "white",
                      position: "absolute",
                      background: "var(--red)",
                      borderRadius: 15,
                      textAlign: "center",
                      height: 25,
                      top: -5,
                      right: -5,
                      width: 25,
                      lineHeight: "25px",
                    }}
                  >
                    {totalQuantity}
                  </div>
                )}
                <FaShoppingCart
                  style={{ color: "#1979c3", height: 25, width: 25 }}
                />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
