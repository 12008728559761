import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { FaShoppingCart } from "react-icons/fa"
import { useTransition } from "react-spring"
import "../sass/app.scss"
import { StoreContext } from "../context/StoreContext"
import Cart from "./Cart/Cart"
import Loader from "./Loader"
import Nav from "./Nav"

const Header = ({ siteTitle }) => {
  const { isCartOpen, toggleCartOpen, checkout } = useContext(StoreContext)

  const transitions = useTransition(isCartOpen, null, {
    from: { transform: "translate3d(100%, 0, 0)" },
    enter: { transform: "translate3d(0, 0, 0)" },
    leave: { transform: "translate3d(100%, 0, 0)" },
  })

  const totalQuantity = checkout.lineItems.reduce(
    (total, item) => total + item.quantity,
    0
  )

  return (
    <>
      <header
        className="level is-mobile"
        style={{
          padding: "10px 5%",
          background: "var(--white)",
          boxShadow: "var(--elevation-2)",
          marginBottom: 0,
        }}
      >
        <div className="level-left">
          <Link to="/">
            <h1 className="is-size-1">Waggtopia</h1>
          </Link>
          <Nav />
        </div>
        <div className="level-right">
          <div>
            <Link to="/cart">
              <button
                className="button"
                style={{
                  background: "transparent",
                  border: "none",
                  position: "relative",
                }}
              >
                {totalQuantity > 0 && (
                  <div
                    style={{
                      color: "white",
                      position: "absolute",
                      background: "var(--red)",
                      borderRadius: 15,
                      textAlign: "center",
                      height: 30,
                      top: -5,
                      right: -5,
                      width: 30,
                      lineHeight: "30px",
                    }}
                  >
                    {totalQuantity}
                  </div>
                )}
                <FaShoppingCart
                  style={{ color: "#1979c3", height: 30, width: 30 }}
                />
              </button>
            </Link>
          </div>
        </div>
        {transitions.map(
          ({ item, key, props }) => item && <Cart key={key} style={props} />
        )}
      </header>
      <Loader />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
