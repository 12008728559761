import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Header from "./header"
import Nav from "./Nav"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <Nav />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <main style={{ minHeight: "90vh" }}>{children}</main>
        <footer
          className="footer"
          style={{ background: "black", color: "white" }}
        >
          <p>© {new Date().getFullYear()}, Waggtopia</p>
          <div className="columns">
            <div className="column is-2">
              Overview
              <hr />
              <Link to="/">
                <p>Home</p>
              </Link>
              <Link to="/about">
                <p>About</p>
              </Link>
              <Link to="/contact">
                <p>Contact</p>
              </Link>
              <Link to="/track">
                <p>Track</p>
              </Link>
            </div>
            <div className="column is-2">
              Legal
              <hr />
              <Link to="/privacy-policy">
                <p>Privacy Policy</p>
              </Link>
              <Link to="/refund-policy">
                <p>Refund Policy</p>
              </Link>
              <Link to="/terms-of-service">
                <p>Terms of Service</p>
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
